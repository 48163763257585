<template>
  <footer class="main-footer-container">
    <div class="main-footer-content">
      <div class="left-container">
        <div class="top-container">
          <sub-title>
            {{ $t('mainFooter.mainTitle') }}
          </sub-title>
          <div class="contact-container">
            <div class="contact-info"
                 v-for="item in contactInfoTranslation"
                 :key="item.id">
                <main-title-card :active-h="'div'">
                  {{ item.title }}
                </main-title-card>
                <main-paragraph>
                  <a :href="'mailto:' + item.email">
                    {{ item.email }}
                  </a>
                </main-paragraph>
              <main-paragraph>
                <a :href="'tel:' + item.phone">
                  {{ item.phone }}
                </a>
              </main-paragraph>
            </div>
          </div>
        </div>
        <div class="bottom-container">
          <div class="contact-info">
            <main-title-card :active-h="'div'">
              {{ $t('mainFooter.city') }}
            </main-title-card>
            <main-paragraph class="address">
              {{ $t('mainFooter.address') }}
            </main-paragraph>
            <main-paragraph>
              {{ $t('mainFooter.schedule') }}
            </main-paragraph>
          </div>
        </div>
      </div>
    </div>
    <div class="right-container">
<!--      <div class="img-container logo">-->
<!--        <img :src="getUrlLogo(logoImg)" alt="logo">-->
<!--      </div>-->
      <div class="img-container map">
        <img :src="getUrlImg(mapImg)" alt="map">
      </div>
    </div>
  </footer>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import PlayButton from "@/components/shared/buttons/PlayButton.vue";

export default {
  name: "MainFooter",
  components: {PlayButton, MainParagraph, MainTitleCard, SubTitle},
  data() {
    return {
      logoImg: 'logo-item-with-text',
      mapImg: 'map',
      contactInfo: [
        {
          email: 'sales@кванта.рф',
          phone: '+7 (812) 318 40 21'
        },
        {
          email: 'support@кванта.рф',
          phone: '8 (800) 500 47 60'
        }
      ]
    }
  },
  computed: {
    contactInfoTranslation() {
      return this.$tm('mainFooter.contactInfo').map((item, index) => ({
        ...item,
        id: index,
        ...this.contactInfo[index]
      }))
    }
  }
}
</script>

<style scoped lang="scss">
.text-content {
  color: var(--color-sub-main) !important;

  & a {
    color: var(--color-sub-main) !important;
    text-decoration: none;
  }
}


.contact-info {
  display: flex;
  flex-direction: column;
}

  .main-footer-container {
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    position: relative;
    background: var(--color-font-delf-blue);
    overflow: hidden;
    .main-footer-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-left: auto;

      .left-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .contact-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .right-container {
      max-width: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .img-container {
        & img {
          width: 100%;
          height: 100%;
        }
        &.logo {
          position: relative;
          z-index: 1;
        }
        &.map {
          width: 100%;
          height: 100%;
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

@media (max-width: 3840px){
  .main-paragraph-container {
    font-size: 42px;
  }
  .contact-info {
    row-gap: 24px;
    .city {
      font-size: 48px;
    }
  }

  .main-footer-container {
    min-height: 1274px;
    max-height: 1274px;
    .main-footer-content {
      max-width: 1696px;

      .left-container {
        padding: 240px 25% 260px 16px;
        .sub-title-container {
          margin-bottom: 56px;
        }
        .top-container {
          .contact-container {
            column-gap: 100px;
            justify-content: flex-start;
          }
          .contact-info {
            row-gap: 24px;
          }
        }

      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 150px;
          max-width: 290px;
          max-height: 220px;
        }
      }
    }
  }
}


@media (max-width: 3024px){
  .main-paragraph-container {
    font-size: 28px;
  }
  .contact-info {
    row-gap: 16px;
    .city {
      font-size: 38px;
    }
  }

  .main-footer-container {
    min-height: 822px;
    max-height: 822px;
    .main-footer-content {
      max-width: 976px;

      .left-container {
        padding: 80px 25% 80px 16px;
        .sub-title-container {
          margin-bottom: 56px;
        }
        .top-container {
          .contact-info {
            row-gap: 16px;
          }
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 50px;
          max-width: 190px;
          max-height: 143px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .main-paragraph-container {
    font-size: 24px;
  }
  .contact-info {
    row-gap: 16px;
    .city {
      font-size: 32px;
    }
  }

  .main-footer-container {
    min-height: 693px;
    max-height: 693px;
    .main-footer-content {
      max-width: 796px;

      .left-container {
        padding: 80px 25% 80px 16px;

        .top-container {
          .contact-info {
            row-gap: 16px;
          }
        }
        .sub-title-container {
          margin-bottom: 56px;
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 96px;
          max-width: 190px;
          max-height: 143px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .main-paragraph-container {
    font-size: 20px;
  }
  .contact-info {
    row-gap: 8px;
    .city {
      font-size: 28px;
    }
  }

  .main-footer-container {
    min-height: 629px;
    max-height: 629px;
    .main-footer-content {
      max-width: 616px;

      .left-container {
        padding: 80px 25% 80px 16px;

        .top-container {
          .contact-info {
            row-gap: 8px;
          }
        }
        .sub-title-container {
          margin-bottom: 56px;
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 56px;
          max-width: 126px;
          max-height: 96px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .main-paragraph-container {
    font-size: 18px;
  }
  .contact-info {
    row-gap: 8px;

    .city {
      font-size: 26px;
    }
  }

  .main-footer-container {
    min-height: 548px;
    max-height: 548px;
    .main-footer-content {
      max-width: 521px;

      .left-container {
        padding: 60px 25% 60px 16px;

        .top-container {
          .contact-info {
            row-gap: 8px;
          }
        }
        .sub-title-container {
          margin-bottom: 36px;
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 56px;
          max-width: 97px;
          max-height: 73px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-paragraph-container {
    font-size: 20px;
  }
  .contact-info {
    row-gap: 8px;

    .city {
      font-size: 28px;
    }
  }

  .main-footer-container {
    min-height: 629px;
    max-height: 629px;
    .main-footer-content {
      max-width: 616px;

      .left-container {
        padding: 80px 25% 80px 16px;

        .top-container {
          .contact-info {
            row-gap: 8px;
          }
        }
        .sub-title-container {
          margin-bottom: 56px;
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 56px;
          max-width: 126px;
          max-height: 96px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .main-paragraph-container {
    font-size: 18px;
  }
  .contact-info {
    row-gap: 8px;

    .city {
      font-size: 26px;
    }
  }

  .main-footer-container {
    min-height: 548px;
    max-height: 548px;
    .main-footer-content {
      max-width: 521px;

      .left-container {
        padding: 60px 25% 60px 16px;

        .top-container {
          .contact-container {
            column-gap: 120px;
          }
          .contact-info {
            row-gap: 8px;
            .main-paragraph-container {
              white-space: nowrap;
            }
          }
        }
        .sub-title-container {
          margin-bottom: 36px;
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 56px;
          max-width: 97px;
          max-height: 73px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .main-paragraph-container {
    font-size: 16px;
  }
  .card-main-title-container {
    font-size: 16px;
  }
  .contact-info {
    row-gap: 8px;

    .city {
      font-size: 20px;
    }
  }

  .main-footer-container {
    padding: 0;
    flex-direction: column;
    min-height: 740px;
    max-height: 740px;
    .main-footer-content {
      height: 386px;
      max-width: 100%;

      .left-container {
        height: inherit;
        row-gap: 32px;
        justify-content: flex-start;
        padding: 63px 18px 30px;

        .top-container {
          .contact-container {
            column-gap: 0;
            justify-content: space-between;
          }
          .contact-info {
            row-gap: 8px;
          }
        }
        .sub-title-container {
          margin-bottom: 16px;
        }
      }
    }
    .right-container {
      height: 100%;
      max-width: 100%;
      .img-container {
        &.logo {
          margin-bottom: 36px;
          max-width: 60px;
          max-height: 45px;
        }
      }
    }
  }
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-paragraph-container {
    font-size: 16px;
  }
  .card-main-title-container {
    font-size: 16px;
  }
  .contact-info {
    row-gap: 8px;

    .city {
      font-size: 20px;
    }
  }

  .main-footer-container {
    padding: 0;
    flex-direction: column;
    min-height: 740px;
    max-height: 740px;
    .main-footer-content {
      height: 386px;
      max-width: 100%;

      .left-container {
        height: inherit;
        row-gap: 32px;
        justify-content: flex-start;
        padding: 63px 22px 30px;

        .top-container {
          .contact-container {
            column-gap: 0;
            justify-content: space-between;
          }
          .contact-info {
            row-gap: 8px;
          }
        }
        .sub-title-container {
          margin-bottom: 16px;
        }
      }
    }
    .right-container {
      height: 100%;
      max-width: 100%;
      .img-container {
        &.logo {
          margin-bottom: 36px;
          max-width: 60px;
          max-height: 45px;
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3841px) {
  .main-paragraph-container {
    font-size: 42px;
  }
  .contact-info {
    row-gap: 24px;

    .city {
      font-size: 48px;
    }
  }

  .main-footer-container {
    min-height: 1274px;
    max-height: 1274px;
    .main-footer-content {
      max-width: 1696px;

      .left-container {
        padding: 240px 25% 260px 16px;
        .sub-title-container {
          margin-bottom: 56px;
        }
        .top-container {
          .contact-container {
            column-gap: 100px;
            justify-content: flex-start;
          }
          .contact-info {
            row-gap: 24px;
          }
        }

      }
    }
    .right-container {
      .img-container {
        &.logo {
          margin-bottom: 150px;
          max-width: 290px;
          max-height: 220px;
        }
      }
    }
  }
}

</style>