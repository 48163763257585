<template>
  <div class="input-container" @click="focusInput"
       :class="[{'focus' : focused, 'entered': textInput.length, 'is-active-alert': alert}]">
    <input :type="info?.type || 'text'"
           :name="info?.dataIndex || 'value'"
           autocomplete="on"
           @focus="focused = true"
           @blur="focused = false"
           :placeholder="info?.placeholder || 'Введите данные'"
           v-model="textInput">
  </div>
</template>

<script>

export default {
  name: "InputWithLine",
  props: {
    alert: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      required: true
    },
    nameBlock: {
      type: String
    },
    activeTextInput: {
      type: String
    }
  },

  mounted() {
    if(this.activeTextInput !== undefined) {
      this.textInput = this.activeTextInput
    }
  },

  data() {
    return {
      textInput: '',
      countLetter: 0,
      focused: false
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
    submitActive() {
      if(this.submitActive) {
        this.textInput = ''
      }
    },
  },
  methods: {
    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      if(this.info.type !== 'textarea') {
        let input = block.querySelectorAll("input")
        input[this.info.id].focus()
      } else {
        let textarea = block.querySelectorAll("textarea")
        textarea[this.info.id].focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">

.input-container {
  overflow: visible;
  cursor: text;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  position: relative;
  transition: all .5s ease;
  height: 100%;
  border-bottom-color: var(--color-font-alice-blue);
  border-bottom-style: solid;

  &:before {
    content: '';
    width: 100%;
    background-color: var(--color-font-delft);
    position: absolute;
    left: 0;
    bottom: -2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: all .6s;
  }
  &:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }

  &.entered:before {
    transform: scaleX(1);
    background-color: var(--color-font-non-photo-blue);
    transform-origin: left;
  }
  &.is-active-alert:before {
    transform: scaleX(1);
    background-color: rgba(var(--color-rgba-red), .2);
    transform-origin: left;
  }

  &.focus:before {
    transform: scaleX(1);
    transform-origin: left;
  }




  & input {
    overflow: visible;
    width: 100%;
    height: 100%;
    color: var(--color-font-squid) !important;
    background-color: rgba(1,1,1,0) !important;

    &::placeholder {
      color: var(--color-font-cool-grey);
      opacity: .4;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}



@media (max-width: 3024px){
  .input-container {
    padding: 24px;
    max-height: 78px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 18px;
    }
  }
}


@media (max-width: 1920px) {
  .input-container {
    padding: 20px;
    max-height: 70px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 15px;
    }
  }
}


@media (max-width: 1600px) {
  .input-container {
    padding: 16px;
    max-height: 62px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 14px;
    }
  }
}
@media (max-height: 800px) {
  .input-container {
    padding: 14px 12px;
    max-height: 54px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .input-container {
    padding: 16px;
    max-height: 62px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 14px;
    }
  }
}

@media (max-width: 1133px) {
  .input-container {
    padding: 14px 12px;
    max-height: 54px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 12px;
    }
  }
}

@media (max-width: 769px) {

  .input-container {
    padding: 23px 12px 12px;
    max-height: 54px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 10px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .input-container {
    padding: 36px;
    max-height: 118px;
    border-bottom-width: 2px;

    &:before {
      height: 2px;
    }

    & input {
      font-size: 32px;
    }
  }
}

</style>