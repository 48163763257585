<template>
  <div class="calculator-screen-main-container"
       :class="{'active-result' : isActiveResult === 1}">
    <div class="top-container" v-animateonscroll="{ enterClass: 'fade-in'}" @animationend="showNextItem">
      <sub-title>
        {{ $t('calculatorScreenMain.mainTitle') }}
      </sub-title>
      <main-paragraph-card :active-h="true">
        {{ $t('calculatorScreenMain.subTitle') }}
      </main-paragraph-card>
    </div>
    <div class="bottom-container">
      <card-calculator v-for="(item, index) in calculatorCard"
                       :translation="calculatorCardTranslation[index]"
                       :value="{value: calculatorCard[index].value}"
                       :active-currency="activeCurrency"
                       :disabled="isActiveResult === 1"
                       :class="{'active' : isVisible(index)}"
                       @changeTextInput="changeValue"
                       @changeCurrency="changeCurrency"
                       :key="index"
                       :item="item"/>
      <transition name="fade-opacity-lazy" mode="out-in">
        <main-button v-if="isActiveResult === 0"
                     class="dark"
                     :disabled="!isValid"
                     :loading="loading"
                     @click="sendValue">
          {{ $t('calculatorScreenMain.buttonText') }}
        </main-button>
        <div class="result-container" v-else-if="isActiveResult === 1">
          <div class="calculator-top-container">
            <div class="left-container">
              <sub-title-card>
                {{ $t('calculatorScreenMain.mainTitleResult') }}
              </sub-title-card>
              <div class="result-text-container">
                {{ resultTextComputed }}
                <span>
            {{ activeCurrency }} {{ $t('calculatorScreenMain.resultTitle') }}
             </span>
              </div>
            </div>
              <main-button
                  @click="resetCalculator"
                  class="dark">
                {{ $t('calculatorScreenMain.buttonTextResult') }}
              </main-button>
          </div>


          <mini-title-card>
            {{ $t('calculatorScreenMain.subTitleResult') }}
          </mini-title-card>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import CardCalculator from "@/components/entities/card/CardCalculator.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import SubTitleCard from "@/components/shared/text/card/SubTitleCard.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";

export default {
  name: "CalculatorScreenMain",
  components: {MainParagraphCard, MiniTitleCard, SubTitleCard, MainButton, SubTitle, CardCalculator},
  data() {
    return {
      visibleIndexItem: -1,
      activeCurrency: '₽',
      loading: false,
      resultText: 10000000,
      isActiveResult: -1,
      calculatorCard: [
        {
          value: 0,
          key: 'numEmployee',
          marks: [0, 5000, 10000],
          maxValue: 10000
        },
        {
          value: 0,
          key: 'numEquipment',
          marks: [0, 500, 1000],
          maxValue: 1000
        },
        {
          value: 0,
          isActiveCurrency: true,
          key: 'salary',
          marks: [0, 100000, 200000],
          maxValue: 200000
        },
        {
          value: 0,
          isActiveCurrency: true,
          key: 'priceEquipment',
          marks: [0, 250000, 500000],
          maxValue: 500000
        },
        {
          value: 0,
          key: 'level',
          marks: [0, 50, 100],
          maxValue: 100
        }
      ]
    }
  },
  computed: {
    calculatorCardTranslation() {
        return this.$tm('calculatorScreenMain.calculatorCard')
    },
    resultTextComputed() {
      return this.resultText.toLocaleString('ru')
    },
    isValid() {
      return this.calculatorCard.every(item => typeof item.value === 'number')
    },
    formResult() {
      let obj = {};
      this.calculatorCard.forEach(item => {
        obj[item.key] = item.value
      })
      return obj;
    }
  },
  methods: {
    resetCalculator() {
      this.calculatorCard.forEach(item => {
        item.value = 0;
      })
      this.isActiveResult = 0;
    },
    showNextItem() {
      if (this.visibleIndexItem < this.calculatorCard.length) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 200)
      } else {
        this.isActiveResult = 0
      }
    },

    sendValue() {
      this.loading = true;
      let lossEmployee = this.formResult.numEmployee * this.formResult.salary * 12 * (this.formResult.level / 100);
      let lossEquipment = this.formResult.numEquipment * this.formResult.priceEquipment * 12 * (this.formResult.level / 100);
      let sumLoss = lossEmployee + lossEquipment;
      this.resultText = sumLoss * 0.8;
      setTimeout(() => {
        this.isActiveResult = 1
        this.loading = false;
      }, 500)
    },
    changeValue(value, key) {
      let findItem = this.calculatorCard.find(item => item.key === key)
      if(findItem) {
        findItem.value = value
      }
    },
    changeCurrency(value) {
      this.activeCurrency = value
    }
  }
}
</script>

<style scoped lang="scss">
  .calculator-screen-main-container {
    display: flex;
    flex-direction: column;
    background: rgba(var(--color-rgba-sub-main), .4);

    .calculator-top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left-container {
        display: flex;
        flex-direction: column;
      }
    }
    .top-container {
      display: flex;
      flex-direction: column;

      .sub-title-container {
        text-transform: uppercase;
        color: var(--color-font-delft)
      }
      .card-main-paragraph-container {
        color: var(--color-font-nickel)
      }
    }
    .bottom-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .card-calculator-container {
        opacity: 0;
        transition: all .6s ease;

        &.active {
          opacity: 1;
        }
      }

      .main-button-container {
        margin: 0 auto;
      }

      & > * {
        flex-basis: 49%;
      }

      .result-container {
        position: relative;
        display: flex;
        flex-direction: column;
        .card-sub-title-container {
          text-transform: uppercase;
          color: var(--color-font-delft);
          margin-bottom: 16px;
        }
        .result-text-container {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          color: var(--color-font-delft);
          font-weight: 600;

          & span {
            white-space: nowrap;
            font-weight: 400;
          }
        }
        .card-mini-title-container {
          font-weight: 400;
          color: var(--color-font-nickel)
        }
      }
    }
  }



  @media (max-width: 3024px){
    .calculator-screen-main-container {
      row-gap: 48px;
      border-radius: 49px;
      padding: 48px;

      .top-container {
        row-gap: 20px;
      }
      .bottom-container {
        row-gap: 38px;
        column-gap: 20px;

        .main-button-container {
          max-width: 442px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          padding: 19.5px 20px 0;
          .card-sub-title-container {
            margin-bottom: 18px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 54px;
            margin-bottom: 20px;

            & span {
              font-size: 24px;
            }
          }
          .card-mini-title-container {
            max-width: 601px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .calculator-screen-main-container {
      row-gap: 40px;
      border-radius: 32px;
      padding: 40px;

      .top-container {
        row-gap: 20px;
      }
      .bottom-container {
        row-gap: 20px;
        column-gap: 20px;

        .main-button-container {
          max-width: 442px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          padding: 20px;
          .card-sub-title-container {
            margin-bottom: 16px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 44px;
            margin-bottom: 20px;

            & span {
              margin-left: 3px;
              font-size: 20px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .calculator-screen-main-container {
      row-gap: 40px;
      border-radius: 32px;
      padding: 40px;

      .top-container {
        row-gap: 20px;
      }
      .bottom-container {
        row-gap: 20px;
        column-gap: 20px;

        .main-button-container {
          max-width: 347px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          padding: 16px;
          .card-sub-title-container {
            margin-bottom: 16px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 40px;
            margin-bottom: 16px;

            & span {
              margin-left: 3px;
              font-size: 20px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .calculator-screen-main-container {
      row-gap: 30px;
      border-radius: 32px;
      padding: 20px;

      .calculator-top-container {
        flex-direction: column;
        align-items: flex-start;
        .left-container {
          width: 100%;
        }

        .main-button-container {
          margin: 0 0 6px
        }
      }
      .top-container {
        row-gap: 12px;
      }
      .bottom-container {
        row-gap: 19px;
        column-gap: 19px;

        .main-button-container {
          max-width: 293px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          justify-content: space-between;
          padding: 16px;
          .card-sub-title-container {
            margin-bottom: 6px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 24px;
            margin-bottom: 6px;

            & span {
              margin-left: 3px;
              font-size: 14px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .calculator-screen-main-container {
      row-gap: 40px;
      border-radius: 32px;
      padding: 40px;
      .calculator-top-container {
        flex-direction: row;
        align-items: center;
        .left-container {
          width: auto;
        }
        .main-button-container {
          margin: 0 auto;
        }
      }
      .top-container {
        row-gap: 20px;
      }
      .bottom-container {
        row-gap: 20px;
        column-gap: 20px;

        .main-button-container {
          max-width: 347px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          padding: 16px;
          .card-sub-title-container {
            margin-bottom: 16px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 40px;
            margin-bottom: 16px;

            & span {
              margin-left: 3px;
              font-size: 20px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .calculator-screen-main-container {
      row-gap: 30px;
      border-radius: 32px;
      padding: 20px;

      .calculator-top-container {
        flex-direction: column;
        align-items: flex-start;
        .left-container {
          width: 100%;
        }
        .main-button-container {
          margin: 0 0 6px
        }
      }
      .top-container {
        row-gap: 12px;
      }
      .bottom-container {
        row-gap: 19px;
        column-gap: 19px;

        .main-button-container {
          max-width: 293px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          justify-content: space-between;
          padding: 16px;
          .card-sub-title-container {
            margin-bottom: 6px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 24px;
            margin-bottom: 6px;

            & span {
              margin-left: 3px;
              font-size: 14px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .calculator-screen-main-container {
      row-gap: 20px;
      border-radius: 32px;
      padding: 16px;
      //transition: height .3s ease;
      //height: 980px;
      //&.active-result {
      //  height: 1070px;
      //}

      .top-container {
        row-gap: 12px;
      }
      .bottom-container {
        row-gap: 8px;
        column-gap: 8px;

        .main-button-container {
          max-width: 100%;
          margin-top: 8px;
        }

        & > * {
          flex-basis: 100%;
        }

        .result-container {
          padding: 16px 0 0;
          .card-sub-title-container {
            margin-bottom: 6px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 20px;
            margin-bottom: 16px;

            & span {
              margin-left: 0;
              font-size: 12px;
            }
          }
          .card-mini-title-container {
            max-width: 565px;
          }
        }
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    //.calculator-screen-main-container {
    //  height: 980px;
    //  &.active-result {
    //    height: 1050px;
    //  }
    //}
  }



  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //.calculator-screen-main-container {
    //  height: 1000px;
    //  &.active-result {
    //    height: 1080px;
    //  }
    //}
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //.calculator-screen-main-container {
    //  height: 1080px;
    //  &.active-result {
    //    height: 1160px;
    //  }
    //}
  }

  @media (min-width: 3025px) {
    .calculator-screen-main-container {
      row-gap: 72px;
      border-radius: 40px;
      padding: 72px;

      .top-container {
        row-gap: 30px;
      }
      .bottom-container {
        row-gap: 50px;
        column-gap: 40px;

        .main-button-container {
          max-width: 785px;
        }

        & > * {
          flex-basis: 49%;
        }

        .result-container {
          padding: 30px 36px 0;
          .card-sub-title-container {
            margin-bottom: 18px;
          }
          .result-text-container {
            column-gap: 4px;
            font-size: 94px;
            margin-bottom: 28px;

            & span {
              margin-left: 16px;
              font-size: 42px;
            }
          }
          .card-mini-title-container {
            max-width: 1008px;
          }
        }
      }
    }
  }

</style>