<template>
  <div class="check-box-container"
       :class="[{'active' : isActive}, type, {'is-active-alert' : alert}]"
       @click="changeStatus">
    <transition name="fade-opacity-lazy">
      <div class="img-container" v-if="isActive">
        <img  :src="getUrlIcons(imgIcon)" alt="check-mark">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    alert: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    activeBlock: {
      type: Boolean,
      default: false
    },
    isActive: {
      required: true
    },
    imgIcon: {
      type: String,
      default: 'check-blue'
    },
  },
  methods: {
    changeStatus() {
      this.$emit('changeStatus')
    }
  }
}
</script>

<style scoped lang="scss">
.check-box-container {
  min-width: var(--check-box-size);
  max-width: var(--check-box-size);
  width: 100%;
  max-height: var(--check-box-size);
  height: 100%;
  min-height: var(--check-box-size);
  border-style: solid;
  border-color: rgba(var(--color-rgba-bg), .2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease;
  background: none;

  &.is-active-alert {
    border-color: rgba(var(--color-rgba-red), .2);
  }

  &:hover {
    border-color: var(--color-font-non-photo-blue);
  }

  &.active {
    border-color: var(--color-font-non-photo-blue);
  }

  &.light {
    border-color: var(--color-sub-main);
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}



@media (max-width: 3024px){
  .check-box-container {
    border-width: 1px;
    --check-box-size: 28px;
    border-radius: 4px;

    .img-container {
      width: 16px;
      height: 8px;
    }
  }
}


@media (max-width: 1920px) {
  .check-box-container {
    border-width: 1px;
    --check-box-size: 24px;
    border-radius: 4px;

    .img-container {
      width: 10px;
      height: 7px;
    }
  }
}


@media (max-width: 1600px) {
  .check-box-container {
    border-width: 1px;
    --check-box-size: 24px;
    border-radius: 4px;

    .img-container {
      width: 10px;
      height: 7px;
    }
  }
}
@media (max-height: 800px) {
  .check-box-container {
    border-width: 1px;
    --check-box-size: 20px;
    border-radius: 4px;

    .img-container {
      width: 9px;
      height: 5px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .check-box-container {
    border-width: 1px;
    --check-box-size: 24px;
    border-radius: 4px;

    .img-container {
      width: 10px;
      height: 7px;
    }
  }
}

@media (max-width: 1133px) {
  .check-box-container {
    border-width: 1px;
    --check-box-size: 20px;
    border-radius: 4px;

    .img-container {
      width: 9px;
      height: 5px;
    }
  }
}

@media (max-width: 769px) {
  //14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .check-box-container {
    border-width: 3px;
    --check-box-size: 42px;
    border-radius: 8px;

    .img-container {
      width: 22px;
      height: 11px;
    }
  }
}


</style>